<template>
	<b-card no-body>
		<b-tabs card>
			<b-tab title="Primary Information" active>
				<b-card-text>
					<b-form-group label="Name:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{
							row.item.firstName +
							' ' +
							row.item.middleName +
							' ' +
							row.item.lastName
						}}
					</b-form-group>
					<b-form-group label="Position:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.position }}
					</b-form-group>
					<b-form-group label="Employee No:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{
							row.item.employeeNo && row.item.employeeNo.length > 0
							? row.item.employeeNo
							: '-'
						}}
					</b-form-group>
					<b-form-group label="Type:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.type }}
					</b-form-group>
					<b-form-group label="Email Address:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.emailAddress }}
					</b-form-group>

					<b-form-group label="Contact No:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.contactNo ? row.item.contactNo : '-' }}
					</b-form-group>

					<b-form-group label="Date Created:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Date Updated:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Driver's License" v-if="hasDriversLicense(row.item.driversLicense)">
				<b-row class="my-12">
					<b-col lg="1" md="1" sm="1" />
					<b-col lg="4" md="8" sm="10" v-show="hasImage(row.item.driversLicense)">
						<b-form-group label="License No" label-class="font-weight-bold pt-0">
							<div class="form-layout">{{ row.item.driversLicense.licenseNo }}</div>
							<br />
							<b-img v-img-orientation-changer :src="row.item.driversLicense.image.url" alt="Responsive image"
								thumbnail fluid />
							<br />
							<a href="#" class="pull-left" @click.prevent="openImage(row.item.driversLicense)">
								Click to view full image
							</a>
						</b-form-group>
					</b-col>
				</b-row>
			</b-tab>

			<b-tab title="Subscription">
				<b-card-text>
					<b-form-group label="Has Notification:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.hasNotif ? 'Yes' : 'No' }}
					</b-form-group>
					<b-form-group label="Enable 2-Way Auth:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.has2WayAuth ? 'Yes' : 'No' }}
					</b-form-group>
					<b-form-group label="Status:" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span v-if="row.item.isActive === 'true'">
							<b-badge variant="success">Active</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">Inactive</b-badge>
						</span>
					</b-form-group>
				</b-card-text>
			</b-tab>

			<b-tab title="Company Access">
				<b-card>
					<b-row>
						<b-col md="12" sm="12" class="my-1">
							<b-table show-empty striped hover :items="companyAccess" :fields="fields"
								:current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>
								<template v-slot:cell(index)="row">{{
									row.index + 1
								}}</template>
							</b-table>
						</b-col>
						<b-col md="8" sm="12" class="my-1">
							<span class="totalDisplay">Total: {{ totalRows }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</b-card>
			</b-tab>

			<b-tab title="Change Company Logs">
				<b-card-text>
					<UserChangeCompanyLogs :row="row" />
				</b-card-text>
			</b-tab>
		</b-tabs>
		<UserLicenseImageView :user="row.item" />
	</b-card>
</template>

<script>
// Component
import UserChangeCompanyLogs from './UserChangeCompanyLogs';
import UserLicenseImageView from './UserLicenseImageView';

// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'user-details-view',
	components: {
		UserChangeCompanyLogs,
		UserLicenseImageView
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			companyAccess: [],
			fields: [
				{
					key: 'index',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
				{
					key: 'description',
					label: 'Description',
					sortable: true,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
		};
	},
	mounted() {
		this.companyAccess = this.row.item.companyAccess
			? this.row.item.companyAccess
			: [];
		this.totalRows = this.companyAccess.length;
	},
	methods: {
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},

		hasImage(url) {
			return url && !_.isEmpty(url);
		},

		openImage(license) {
			EventBus.$emit('onSelUserLicenseImageView', {
				license: license,
			});
			this.$bvModal.show('user-drivers-license-image-view');
		},

		hasDriversLicense(license) {
			return license && license.image && license.image.url;
		},
	},
};
</script>

<style scoped></style>
