<template>
	<div>
		<b-row class="mt-3">
			<b-col sm="12">
				<i class="icon-layers"></i>&nbsp;
				<span class="header">SELECT COMPANIES</span>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col sm="6" class="my-2">
				<i class="fa fa-filter"></i>&nbsp;
				<b>FILTER OPTIONS</b>
			</b-col>
			<b-col sm="6" class="my-2" align="right">
				<div
					v-show="!showFilterOptions"
					size="sm"
					@click="toggleFilterOptions"
					v-b-tooltip.hover.top="'Show/Hide Filter Options'"
				>
					<i class="icon-arrow-down"></i>
				</div>
				<div
					v-show="showFilterOptions"
					size="sm"
					@click="toggleFilterOptions"
					v-b-tooltip.hover.top="'Show/Hide Filter Options'"
				>
					<i class="icon-arrow-up"></i>
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-2 mb-4" v-show="showFilterOptions">
			<b-col md="12" sm="12">
				<b-form-group
					label="Company Name"
					label-for="name"
					description="Please enter at least 3 characters to proceed with search"
				>
					<b-form-input
						id="name"
						name="Name"
						type="text"
						v-model="filterBy.name"
						v-validate="{
							required: true,
							regex: /^[ÑA-Za-z0-9][ñA-Za-z0-9\s&\'\-,.\/]{1,100}$/,
						}"
						placeholder="Name"
					/>
					<span v-show="errors.has('Name')" class="help-block">
						{{ errors.first('Name') }}
					</span>
				</b-form-group>
			</b-col>
			<b-col md="12" sm="12" align="right">
				<b-button variant="primary" @click="onReset" size="sm">
					Reset
				</b-button>
				&nbsp;
				<b-button variant="success" @click="onSearch" size="sm">
					Search
				</b-button>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col sm="10" class="my-1 text-sm-left">
				<b-button size="sm" @click="selectAllRows">Select all</b-button>&nbsp;
				<b-button size="sm" @click="clearSelected">Clear</b-button>&nbsp;

				<span class="totalDisplay">
					Selected: {{ Object.keys(selected).length }}
				</span>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<span class="totalDisplay">Total: {{ totalRows }}</span>
			</b-col>
			<b-col sm="1" class="my-1 text-sm-right">
				<b-button
					variant="success"
					@click="addSelectedCompanies"
					v-b-tooltip.hover.top="'Add Selected Companies'"
					class="mr-1"
				>
					<i class="fa fa-plus"></i>
				</b-button>
			</b-col>
		</b-row>

		<b-row class="mx-1">
			<b-table
				ref="companiesTable"
				show-empty
				striped
				hover
				:items="items"
				:fields="fields"
				:current-page="currentPage"
				:per-page="perPage"
				:filter="filter"
				:sort-by.sync="sortBy"
				:sort-desc.sync="sortDesc"
				:sort-direction="sortDirection"
				responsive
				selectable
				select-mode="multi"
				@row-selected="onRowSelected"
				selected-variant="primary"
			>
				<template v-slot:cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>
			</b-table>
		</b-row>
		<b-row>
			<b-col sm="6" class="text-sm-right">
				<b-input-group prepend="Show" append="/ Page" size="sm">
					<b-form-select :options="pageOptions" v-model="perPage" />
				</b-input-group>
			</b-col>
			<b-col sm="6">
				<b-pagination
					align="right"
					:total-rows="totalRows"
					:per-page="perPage"
					v-model="currentPage"
					size="sm"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Util
import { SortUtil } from '@/utils/sortUtil';

// DAO
import companyDAO from '@/database/companies';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import _ from 'lodash';

export default {
	name: 'sibling-company-table',
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
			],
			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			// for filtering
			filterBy: {
				name: '',
			},

			// for selected fields
			selected: {},
			allCompaniesOptions: [],

			showFilterOptions: false,

			loggedUserCompany: this.$store.getters.loggedUserCompany,
		};
	},
	watch: {
		allCompaniesOptions: function () {
			this.items = this.allCompaniesOptions;
			this.totalRows = this.items.length;
		},
	},
	async mounted() {
		// preload company list
		await this.retrieveCompanyOptions();

		EventBus.$on('onDeleteCompany', (deletedCompany) => {
			let companyNamesArr = _.map(this.items, 'name');
			if (!_.includes(companyNamesArr, deletedCompany.name)) {
				this.items.push(deletedCompany);
				this.items = SortUtil.sortByAttr('name', this.items);
				this.totalRows = this.items.length;
			}
		});
	},
	methods: {
		onReset() {
			this.filterBy.name = '';
			this.onSearch();

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		onSearch() {
			if (!_.isEmpty(this.filterBy.name)) {
				let filteredItems = [];
				_.forEach(this.allCompaniesOptions, (o) => {
					if (o.name.includes(this.filterBy.name)) {
						filteredItems.push(o);
					}
				});
				this.items = filteredItems;
			} else {
				this.items = this.allCompaniesOptions;
			}
		},
		onRowSelected(items) {
			this.selected = items;
		},
		selectAllRows() {
			this.$refs.companiesTable.selectAllRows();
		},
		clearSelected() {
			this.$refs.companiesTable.clearSelected();
		},
		toggleFilterOptions() {
			this.showFilterOptions = !this.showFilterOptions;
		},
		addSelectedCompanies() {
			if (_.isEmpty(this.selected)) {
				this.$toaster.warning(
					'Please select the companies you want to include for this assignment of access.'
				);
				return;
			}

			let selCompaniesArr = Object.values(this.selected);
			EventBus.$emit('addSelectedCompanies', selCompaniesArr);

			// Remove the transported companies from the list of available companies
			this.removeSelectedCompanies(selCompaniesArr);
		},
		removeSelectedCompanies(selCompaniesArr) {
			let transportedCompaniesOptions = [];
			selCompaniesArr.forEach((asset) => {
				transportedCompaniesOptions.push(asset.id);
			});

			this.items = this.items.filter(function (value, _index, _arr) {
				return !transportedCompaniesOptions.includes(value.id);
			});
			this.totalRows = this.items.length;
		},
		async retrieveCompanyOptions() {
			try {
				if (!_.isEmpty(this.user)) {
					let companiesObj = await companyDAO.getSiblingCompanies(
						this.user.companyId
					);

					this.allCompaniesOptions = [];

					_.forEach(companiesObj, (company) => {
						this.allCompaniesOptions.push({
							id: company.id,
							name: company.name,
							description: company.description,
						});
					});

					let companyIds = _.map(this.user.companyAccess, 'id');
					if (!_.isEmpty(companyIds)) {
						this.allCompaniesOptions = _.filter(
							this.allCompaniesOptions,
							(o) => {
								return !companyIds.includes(o.id);
							}
						);
						EventBus.$emit('addSelectedCompanies', this.user.companyAccess);
					}
				}
			} catch (error) {
				console.log(error);
				this.$toaster.warning('Error loading initial companies.');
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onDeleteCompany');
	},
};
</script>

<style scoped>
</style>
